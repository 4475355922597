



























































































































import { IOrder, IPosition } from "@/core/types";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class ProductPositionModal extends Vue {
  currency: "client" | "asset" = "client";

  getAmount(amt: any) {
    return amt[this.currency + "Currency"];
  }

  toggleCurrency() {
    this.currency = this.currency === "client" ? "asset" : "client";
  }

  formatDate(date: string): string {
    const d = new Date(date);
    const time = d.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    });
    return `${d.getDate()} ${d.toLocaleString("default", {
      month: "short",
    })}. ${time}`;
  }

  get position(): IPosition {
    return this.$store.state.product.position;
  }
  get logoUrl(): string {
    return this.$store.state.product.logoUrl;
  }
  get orders(): IOrder[] {
    return this.$store.state.product.orders;
  }

  get ordersByMonth(): Record<string, IOrder[]> {
    const orders = this.orders;
    const months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    const ordersByMonth: Record<string, IOrder[]> = {};

    orders.forEach((o: IOrder) => {
      const month = new Date(o.createdAt).getMonth();
      const year = new Date(o.createdAt).getFullYear();
      const key = `${months[month]} ${year}`;
      if (!ordersByMonth[key]) {
        ordersByMonth[key] = [];
      }
      ordersByMonth[key].push(o);
    });

    return ordersByMonth;
  }
}
